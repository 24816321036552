import '@fontsource/material-icons';
import '@fontsource/montserrat';
import '@fontsource/cabin';
import 'intl';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useGetBreakpoint } from '@ict-trust/dgt-blocks/src/hooks/useBreakpoints';
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import * as Sentry from '@sentry/react';

import { AnalyticsProvider, getInitialPageId } from '../components/analytics';
import { Footer } from '../components/footer/footer';
import { Header } from '../components/header/header';
import { Seo } from '../components/seo';
import { theme } from '../components/theme';
import {
  CMS_GRAPHQL_API,
  CMS_GRAPHQL_API_KEY,
  ONE_TRUST_KEY,
} from '../environment';
import { isInFrame } from '../templates/page.utils';
import { TemplateProps } from '../templates/template.types';
import {
  defaultLocale,
  getExternalDomain,
  getPageMessages,
  getSavedUserLocale,
  getUserLocale,
  localiseUrl,
} from '../utils/language';

const routesWithGoogleMapsAPI = ['/quote/', '/booking/'];
const pageIdsWithoutFooter = ['quote'];
const pageIdsWithoutHeader: string[] = [];

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: CMS_GRAPHQL_API,
  headers: {
    Authorization: `Bearer ${CMS_GRAPHQL_API_KEY}`,
  },
});

const useStyles = makeStyles(() => ({
  root: {
    '& .highlight': {
      color: '#12394F',
    },
  },
}));

const hasFooter = (pageId: string) =>
  !isInFrame() && !pageIdsWithoutFooter.includes(pageId);

const hasHeader = (pageId: string) =>
  !isInFrame() && !pageIdsWithoutHeader.includes(pageId);

export const LoadingContext = React.createContext<{
  isOverlay: boolean;
  setOverlay: (message: string | undefined) => void;
}>({
  isOverlay: false,
  setOverlay: () => void null,
});

export const SiteContent: React.FC<{ pageId: string }> = ({
  children,
  pageId,
}) => {
  const [isBreakpointsLoaded, setBreakpointsLoaded] = useState<boolean>(false);
  const [isOverlay, setOverlay] = useState<string | undefined>('Loading..');
  const currentBreakpoint = useGetBreakpoint();
  const classes = useStyles();

  /**
   * Ensure page does not start rendering until breakpoints are available
   */
  useEffect(() => {
    if (currentBreakpoint && !isBreakpointsLoaded) {
      setBreakpointsLoaded(true);
      setOverlay(undefined);
    }
  }, [currentBreakpoint]);

  return (
    <LoadingContext.Provider
      value={{
        isOverlay: !!isOverlay,
        setOverlay,
      }}
    >
      <Backdrop open={!!isOverlay} style={{ zIndex: 1 }}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Typography
              style={{ color: '#ffffff', fontWeight: 'bold' }}
              variant="h5"
            >
              {isOverlay}
            </Typography>
          </Grid>
          <Grid item>
            <CircularProgress color="primary" />
          </Grid>
        </Grid>
      </Backdrop>
      {isBreakpointsLoaded && (
        <>
          <Helmet>
            {!isInFrame() && ONE_TRUST_KEY && (
              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                type="text/javascript"
                data-domain-script={ONE_TRUST_KEY}
                data-dLayer-name={getInitialPageId(pageId)}
              ></script>
            )}
            <script src="https://cdn.jsdelivr.net/npm/focus-visible@5.0.2/dist/focus-visible.min.js" />
            {!isInFrame() && (
              <script
                defer
                id="tcx-callus-js"
                src="https://cdn.3cx.com/livechat/v1/callus.js"
              />
            )}
          </Helmet>

          <Box
            style={{
              position: 'absolute',
              width: '100%',
              backgroundColor: '#EFEDE9',
            }}
            className={classes.root}
          >
            {hasHeader(pageId) && <Header pageId={pageId} />}

            <Grid
              item
              style={{
                flexGrow: 1,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {children}
            </Grid>

            {hasFooter(pageId) && (
              <Grid item>
                <Footer />
              </Grid>
            )}
          </Box>
        </>
      )}
    </LoadingContext.Provider>
  );
};

export const SiteLayout: React.FC<TemplateProps> = ({
  children,
  ...templateProps
}) => {
  const { pageContext } = templateProps;
  const { pageId, locale, i18nMessages, pagePath } = pageContext;
  const pageMessages = getPageMessages(pageId, i18nMessages);

  /**
   * Report 404s to Sentry
   */
  if (pageId === '404') {
    Sentry.captureMessage('404', {
      level: Sentry.Severity.Warning,
    });
  }

  /**
   * Determines if user locale differs from set locale, redirecting site if necessary
   */
  useEffect(() => {
    // Only apply translations for en-AU site
    if (defaultLocale !== 'en-AU') {
      return;
    }

    const userLocale = getUserLocale();

    // User locale matches site locale
    if (userLocale === locale) {
      return;
    }
    // Overridden by user, ignore
    if (locale === getSavedUserLocale()) {
      return;
    }

    // Reload to correct site locale
    // - do not reload if the locale has it's own domain
    const externalDomain = getExternalDomain(userLocale);
    if (!externalDomain) {
      location.replace(localiseUrl(undefined, userLocale));
    }
  }, [locale]);

  const chatRef = useRef<NodeJS.Timer | null>(null);

  const renderFBchat = useCallback(() => {
    if (defaultLocale !== 'en-GB') {
      const chatbox = document.getElementById('fb-customer-chat');

      if (chatbox) {
        chatbox.setAttribute('page_id', '149230748435817');
        chatbox.setAttribute('attribution', 'biz_inbox');

        window.fbAsyncInit = function () {
          window.FB.init({
            xfbml: true,
            version: 'v17.0',
          });
        };

        (function (d, s, id) {
          const fjs = d.getElementsByTagName(s)[0];

          if (d.getElementById(id)) return;

          const js: HTMLScriptElement = d.createElement('script');

          js.id = id;
          js.src =
            'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';

          fjs?.parentNode?.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
      } else {
        if (chatRef.current) {
          clearInterval(chatRef.current);
          chatRef.current = null;
        }
      }
    }
  }, []);

  useEffect(() => {
    if (defaultLocale === 'en-GB') return;

    chatRef.current = setInterval(() => renderFBchat(), 200);

    return () => {
      if (chatRef.current) clearInterval(chatRef.current);
    };
  }, []);

  return (
    <IntlProvider locale={locale} messages={pageMessages}>
      <Helmet>
        {routesWithGoogleMapsAPI.includes(pagePath) && (
          <script
            type="text/javascript"
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA9zEpcAYejgyEn59WJkpxbtyAWAEhK3Lg&libraries=places"
          />
        )}
        {defaultLocale === 'en-GB' && (
          <script id="facebook-pixel-script">
            {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '730567184839241');
            fbq('track', 'PageView');`}
          </script>
        )}
        {defaultLocale === 'en-GB' && (
          <noscript id="facebook-pixel-image">
            {`<img
            height="1"
            width="1"
            style={{ display: 'none' }}
            src="https://www.facebook.com/tr?id=730567184839241&ev=PageView&noscript=1"
            />
          `}
          </noscript>
        )}
      </Helmet>
      <AnalyticsProvider pageId={pageId}>
        <Seo {...templateProps} />
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SiteContent pageId={pageId}>{children}</SiteContent>
          </ThemeProvider>
        </ApolloProvider>
      </AnalyticsProvider>
    </IntlProvider>
  );
};

export default SiteLayout;
