import { parse } from 'bcp-47';
import { ResolvedIntlConfig } from 'react-intl';

import {
  normaliseUrl,
  stripOutsideSlashes,
} from '@ict-trust/dgt-blocks/src/utils/url';

import i18nSettings from '../../content/settings/i18n.json';
import { DEFAULT_LOCALE } from '../environment';
import { Locale } from '../typings/i18n.types';

export const defaultLocale = DEFAULT_LOCALE as Locale;
export const fallbackLocale = i18nSettings.fallbackLocale as Locale;
export const supportedLocales = i18nSettings.locales as Locale[];
export const localeDomains = i18nSettings.localeDomains as Record<
  Locale,
  string // Domain URL
>;
const defaultLanguage = parse(defaultLocale).language;
const defaultCountryCode = parse(defaultLocale).region;

// Ensure defaults are set on build
if (!defaultLanguage || !defaultCountryCode) {
  throw Error('Unable to parse default language or country from config');
}

export const getExternalDomain = (locale: Locale): string | undefined =>
  localeDomains[locale];

// Overrides selected locale for user
export const saveUserLocale = (locale: Locale): void =>
  localStorage.setItem('locale', locale);

// Retrieves persisted locale or returns default
export const getSavedUserLocale = (): Locale | undefined =>
  localStorage.getItem('locale') as Locale | undefined;

// Read user locales from browser
function getUserLanguages(): Locale[] {
  if (typeof navigator === `undefined`) {
    throw Error('Navigator global not defined');
  }
  const langs = navigator.languages;
  return (langs as Locale[]) ?? [];
}

// Match user locale to supported locale or return default
export function getUserLocale(): Locale {
  const userLangs = getUserLanguages() ?? [];
  for (const lang of userLangs) {
    const isMatch = supportedLocales.includes(lang);
    if (!isMatch) {
      continue;
    }
    return lang;
  }

  return defaultLocale;
}

// Return user language (ISO 639-2)
export function getUserLanguage(): string {
  const locale = getUserLocale();
  const schema = parse(locale);
  return schema.language ?? defaultLanguage ?? 'en';
}

//  Return user country code (ISO 3166-1 alpha-2)
export function getUserCountryCode(): string {
  const locale = getUserLocale();
  const schema = parse(locale);
  return schema.region ?? defaultCountryCode ?? 'AU';
}

export function getLocaleCountryCode(locale: Locale): string {
  const supportedCountries = ['au', 'nz', 'gb', 'kr', 'jp', 'cn'];
  const schema = parse(locale);
  const isMatch = supportedCountries.includes(
    String(schema.region).toLowerCase(),
  );
  return isMatch ? String(schema.region).toLowerCase() : String(defaultLocale);
}

export function getLocaleCountry(locale: Locale): string {
  const schema = parse(locale);
  switch (String(schema.region).toLowerCase()) {
    case 'au':
      return 'Australia';
    case 'nz':
      return 'New Zealand';
    case 'gb':
      return 'UK';
    case 'kr':
      return 'Korea';
    case 'jp':
      return 'Japan';
    case 'cn':
      return 'China';
    default:
      return String(defaultLocale).toLowerCase() === 'en-au'
        ? 'Australia'
        : String(defaultLocale).toUpperCase();
  }
}

export const localeCountryId = defaultLocale.slice(-2);

/**
 * Parses current locale from URL, falling back to default
 */
export function getCurrentLocale(): Locale {
  const pathname = typeof location === `undefined` ? '/' : location.pathname;
  const path = stripOutsideSlashes(pathname);
  const relativePathParts = path.split('/');
  const matchedLocale = supportedLocales.find(
    (l) => l.toLowerCase() === relativePathParts[0].toLowerCase(),
  );
  return matchedLocale || defaultLocale;
}

/**
 * Strips any locale prefixes from url, returning relative path
 */
function getBareRelativeUrl(relativeUrl?: string): string {
  const pathname = typeof location === `undefined` ? '/' : location.pathname;
  const path = stripOutsideSlashes(relativeUrl ?? pathname);
  const relativePathParts = path.split('/');
  const matchedLocale = supportedLocales
    .map((l) => l.toLowerCase())
    .includes(relativePathParts[0].toLowerCase() as Locale);
  if (matchedLocale) {
    return relativePathParts.slice(1).join('/');
  }
  return `/${path}/`;
}

/**
 * Applies location prefix to given path if applicable
 */
export function localiseUrl(
  relativeUrl?: string,
  overrideLocale?: Locale,
  forceAbsoluteUri = false, // ensure full path returned, even for default locale
): string {
  // strip locale data from given url
  const bareUrl = getBareRelativeUrl(relativeUrl);
  // determine cuirrent locale (unless overridden)
  const currentLocale =
    typeof location === `undefined` ? defaultLocale : getCurrentLocale();
  const locale = overrideLocale ?? currentLocale;
  // no prefix needed for default locale
  if (!forceAbsoluteUri && defaultLocale === locale) {
    return normaliseUrl(stripOutsideSlashes(bareUrl));
  }
  // override domain if exists
  const domainPrefix = localeDomains[locale];

  // return translation prefix
  const bareUrlStripped = stripOutsideSlashes(bareUrl);
  if (domainPrefix) {
    return normaliseUrl([domainPrefix, bareUrlStripped].join('/'));
  }
  return normaliseUrl([`/${locale}`, bareUrlStripped].join('/'));
}

/**
 * Extracts page-scoped messages from translations
 *
 * Note: 'site' scoped messages are available on every page
 */
export function getPageMessages(
  pageId: string,
  allMessages: ResolvedIntlConfig['messages'],
): ResolvedIntlConfig['messages'] {
  const pageMessages: ResolvedIntlConfig['messages'] = {};
  for (const [key, value] of Object.entries(allMessages)) {
    const [pageKey, messageKey] = key.split(':');
    if (pageKey !== 'site' && pageKey !== pageId) {
      continue;
    }
    if (pageMessages[messageKey]) {
      console.warn(
        `Existing translation entry exists for key ${messageKey} - this might be a site-side key conflict?`,
        { messageKey, value },
      );
    }
    pageMessages[messageKey] = value;
  }
  return pageMessages;
}
