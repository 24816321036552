import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import React, { createRef, useEffect, useState } from 'react';

import { Image } from '@ict-trust/dgt-blocks/src/components';
import {
  IconCaretDown,
  IconPhone,
} from '@ict-trust/dgt-blocks/src/components/icon';
import { normaliseUrl } from '@ict-trust/dgt-blocks/src/utils/url';
import {
  Box,
  Button,
  ButtonBase,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  MenuItem as MuiMenuItem,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useImages } from '../../../../hooks/useImages';
import { useSiteMessage } from '../../../../hooks/useSiteMessage';
import { localiseUrl } from '../../../../utils/language';
import { defaultLocale } from '../../../../utils/language';
import { LocaleMenu } from '../locale/LocaleMenu';
import { MenuItem } from '../nav';

type MenuItemRefs = Record<string, React.RefObject<HTMLButtonElement>>;
type MenuItemChildRefs = Record<string, React.RefObject<HTMLLIElement>>;

type StyleProps = {
  headerTextColour: string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: (props: StyleProps) => props.headerTextColour,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 12,
      paddingRight: 12,
    },
  },
  iconButton: {
    color: (props: StyleProps) => props.headerTextColour,
    textDecoration: 'none',
    '& svg': {
      width: 18,
      height: 18,
    },
  },
  toolbar: {
    justifyContent: 'space-between',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',

    '& > button': {
      marginLeft: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
  },
  nestedList: {
    paddingLeft: '1rem',
  },
  link: {
    marginLeft: '1rem',
    padding: '16px 8px',
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  linkWithMenu: {
    alignItems: 'center',
    paddingRight: '8px',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  headerTextColour: string;
  menuItems: MenuItem[];
  onCall: () => void;
};

export const DesktopMenu: React.FC<Props> = ({
  headerTextColour,
  onCall,
  menuItems,
}) => {
  const styleProps: StyleProps = { headerTextColour };
  const classes = useStyles(styleProps);
  const { getImageData } = useImages();
  const shouldShowLocaleMenu = defaultLocale === 'en-AU';
  const getSiteMessage = useSiteMessage();

  const [menuItemRefs, setMenuItemRefs] = useState<MenuItemRefs>({});
  const [menuItemChildRefs, setMenuItemChildRefs] = useState<MenuItemChildRefs>(
    {},
  );
  const [openMenuItems, setOpenMenuItem] = useState<Record<string, boolean>>(
    {},
  );
  const isOpenMenuItem = (id: string): boolean => !!openMenuItems[id];

  // Set all menu item refs on load
  useEffect(() => {
    const newRefs: MenuItemRefs = {};
    const newChildRefs: MenuItemChildRefs = {};
    for (const { id, menuItems: childItems } of menuItems) {
      newRefs[id] = createRef();
      for (const { id: childId } of childItems) {
        newChildRefs[childId] = createRef();
      }
    }
    setMenuItemRefs(newRefs);
    setMenuItemChildRefs(newChildRefs);
  }, [menuItems]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      closeAllMenus();
    }
  }

  function closeAllMenus() {
    for (const label of [
      ...Object.keys(menuItemRefs),
      ...Object.keys(menuItemChildRefs),
    ]) {
      setOpenMenuItem((c) => ({
        ...c,
        [label]: false,
      }));
    }
  }

  function onToggleMenu(label: string) {
    setOpenMenuItem((c) => ({
      ...c,
      [label]: !c[label],
    }));
  }

  const chooseMenuType = (item: MenuItem) =>
    item.link ? (
      <div className={classes.menuItem} key={item.id}>
        <Link
          to={item.link}
          className={classNames(classes.iconButton, classes.link, {
            [classes.linkWithMenu]: !!item?.menuItems.length,
          })}
        >
          {getSiteMessage(item.id, item.label)}
        </Link>
        {!!item?.menuItems.length && (
          <IconButton
            className={classNames(classes.iconButton)}
            ref={menuItemRefs[item.id]}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              onToggleMenu(item.id);
              if (!item?.menuItems.length) {
                navigate(localiseUrl(item.link));
              }
            }}
          >
            {SubMenu(item, true)}
          </IconButton>
        )}
      </div>
    ) : (
      <IconButton
        key={item.id}
        ref={menuItemRefs[item.id]}
        className={classes.iconButton}
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => {
          onToggleMenu(item.id);
          if (!item?.menuItems.length) {
            navigate(localiseUrl(item.link));
          }
        }}
      >
        <Typography>{getSiteMessage(item.id, item.label)}</Typography>
        &nbsp;
        {!!item?.menuItems.length && SubMenu(item)}
      </IconButton>
    );

  const SubMenu = (item: MenuItem, isArrow = false) => (
    <>
      <IconCaretDown />
      <Popper
        open={isOpenMenuItem(item.id)}
        anchorEl={menuItemRefs[item.id]?.current}
        role={undefined}
        transition
        disablePortal
        placement={isArrow ? 'bottom-end' : 'bottom'}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={closeAllMenus}>
                <MenuList
                  autoFocusItem={isOpenMenuItem(item.id)}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {item.menuItems?.map((childItem: MenuItem) => (
                    <MuiMenuItem
                      key={childItem.id}
                      ref={menuItemChildRefs[childItem.id]}
                      onClick={() => {
                        closeAllMenus();
                        navigate(localiseUrl(childItem.link));
                      }}
                      onMouseEnter={() => {
                        const hasChildItems = !!childItem.menuItems?.length;
                        if (hasChildItems) {
                          onToggleMenu(childItem.id);
                        }
                      }}
                      style={{
                        overflow: 'visible',
                      }}
                    >
                      {getSiteMessage(childItem.id, childItem.label)}{' '}
                      {!!childItem.menuItems?.length && (
                        <Typography
                          variant="body2"
                          style={{
                            paddingLeft: '0.5rem',
                            lineHeight: 0,
                          }}
                        >
                          <IconCaretDown
                            style={{
                              transform: 'rotate(-90deg)',
                            }}
                          />
                        </Typography>
                      )}
                      <Popper
                        open={isOpenMenuItem(childItem.id)}
                        anchorEl={menuItemChildRefs[childItem.id]?.current}
                        placement="right-end"
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {(childProps) => (
                          <Grow
                            {...childProps.TransitionProps}
                            style={{
                              transform: 'translate(0, 8px)',
                            }}
                          >
                            <Paper
                              style={{
                                borderRadius: '0 4px 4px 0',
                                position: 'fixed',
                                top: '-3rem',
                              }}
                            >
                              <MenuList
                                id="menu-list-child-grow"
                                onKeyDown={handleListKeyDown}
                              >
                                {childItem.menuItems?.map(
                                  (gChildItem: MenuItem) => (
                                    <MuiMenuItem
                                      key={gChildItem.id}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        closeAllMenus();
                                        navigate(localiseUrl(gChildItem.link));
                                      }}
                                    >
                                      {getSiteMessage(
                                        gChildItem.id,
                                        gChildItem.label,
                                      )}{' '}
                                    </MuiMenuItem>
                                  ),
                                )}
                              </MenuList>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </MuiMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  return (
    <Toolbar className={classes.toolbar} disableGutters>
      <Box className={classes.menuItems}>
        <Link to={localiseUrl('/')}>
          <ButtonBase style={{ height: 44, width: 160 }}>
            <Image
              getImageData={getImageData}
              src={getSiteMessage('logo', 'logo_en-AU.png')}
              alt="Dogtainers Logo"
              options={{
                height: 44,
                width: '100%',
              }}
            />
          </ButtonBase>
        </Link>
        {shouldShowLocaleMenu && (
          <LocaleMenu headerTextColour={headerTextColour} />
        )}
        {menuItems.map(chooseMenuType)}
      </Box>
      <Box className={classes.menuItems}>
        <IconButton
          className={classes.iconButton}
          aria-label="menu-phone"
          onClick={onCall}
        >
          <IconPhone fontSize="small" />
          &nbsp;
          <Typography>{getSiteMessage('navPhone', 'Call Us')}</Typography>
        </IconButton>
        &nbsp;
        <Button
          variant="outlined"
          color="inherit"
          href={normaliseUrl(getSiteMessage('navQuoteLink', '/quote'))}
        >
          <Typography>
            {getSiteMessage('navQuoteLabel', 'Get a quote')}
          </Typography>
        </Button>
      </Box>
    </Toolbar>
  );
};
