/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FormikTouched, useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { PageControl } from '@ict-trust/dgt-blocks/src/components/form';
import { useBlockStyles } from '@ict-trust/dgt-blocks/src/hooks/useStyles';
import {
  BlockBuilder,
  BlockForm,
} from '@ict-trust/dgt-blocks/src/types/_blocks.types';
import { Box, CircularProgress, Grid, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { WindowLocation, useLocation } from '@reach/router';

import { ErrorBlock } from '../../components/ErrorBlock';
import { useStore } from '../../components/store';
import FormErrorProvider from '../../contexts/FormError/FormError.provider';
import { API_URL, DEFAULT_LOCALE } from '../../environment';
import { useAnalytics } from '../../hooks/useAnalytics';
import { useScrollToError } from '../../hooks/useScrollToError';
import { LoadingContext } from '../../layouts/site.layout';
import { isInFrame } from '../../templates/page.utils';
import { getDistanceBeetwenPlaces } from '../../utils';
import { calculateDayDateDiff } from '../../utils/date';
import {
  processGpsForDistance,
  totalDistance,
} from '../../utils/googleMap.utils';
import { defaultLocale, localiseUrl } from '../../utils/language';
import { RecursivePartial } from '../booking/booking.types';
import { ContactForm } from '../contact/contact.types';
import { isGBLocale } from '../utils';
import { parseErrors } from '../utils/validation';
import { QuoteChat } from './components/chat';
import { useQuoteConfiguration } from './hooks/useQuoteConfiguration';
import { QuotePage1 } from './pages/page1';
import { QuotePage2 } from './pages/page2';
import { QuotePage3 } from './pages/page3';
import { QuotePage4 } from './pages/page4';
import { QuotePage5 } from './pages/page5';
import {
  BookingQuote,
  Country,
  PetAge,
  PetBreed,
  PetSubtype,
  PetType,
  QuoteForm,
  QuoteFormPartial,
} from './quote.types';
import { initValidationSchema, mapFieldToPage } from './quote.validation';

export const useFormStyles = makeStyles((theme) => ({
  buttonWhite: {
    justifyContent: 'flex-start',
    backgroundColor: '#ffffff',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  container: {
    flexWrap: 'nowrap',
  },
}));

const initRootFormValues = (
  defaultQuoteType?: QuoteForm['quoteType'],
): QuoteFormPartial => ({
  id: uuidv4(),
  ...(defaultQuoteType && { quoteType: defaultQuoteType }),
  arrival: {
    isDelivery: false,
    isWithin5Days: true,
  },
  departure: {
    isPickup: false,
  },
  pets: [
    {
      id: uuidv4(),
    },
  ],
  options: {
    isExQuarantine: false,
    requiresReturnFlight: false,
    requiresPetAccommodation: false,
    isMoving: false,
    isDataSharingEnabled: false,
    isBreeder: window.previousPath.includes('pet-travel-breeders'),
    isAustralianDefenceForceMember: false,
  },
});

const bookingQuoteToQuoteForm = (bookingQuote: BookingQuote): QuoteForm => ({
  id: bookingQuote.id,
  arrival: bookingQuote.arrival,
  departure: bookingQuote.departure,
  contact: bookingQuote.contact,
  options: bookingQuote.options,
  quoteType: bookingQuote.quoteType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pets: bookingQuote.pets.map(({ totalCost, ...petRest }) => petRest),
  consultantId: bookingQuote.consultantId,
  referrerId: bookingQuote.referrerId,
});

type Props = BlockBuilder<BlockForm>;

export type Nodes<Type> = { nodes: Type[] };
export type FormDataQuery = {
  allCatsBreedJson: Nodes<PetBreed>;
  allCountriesJson: Nodes<Country>;
  allDogsBreedJson: Nodes<PetBreed>;
  allBirdsBreedJson: Nodes<PetBreed>;
  allReptilesBreedJson: Nodes<PetBreed>;
  allGuineaPigsBreedJson: Nodes<PetBreed>;
  allRabbitsBreedJson: Nodes<PetBreed>;
  allAmphibianBreedJson: Nodes<PetBreed>;
  allOtherBreedJson: Nodes<PetBreed>;
  allPetAgesJson: Nodes<PetAge>;
  allPetTypesJson: Nodes<PetType>;
  allPetSubtypesJson: Nodes<PetSubtype>;
};

export type PageFormProps = {
  values: QuoteFormPartial;
  errors: QuoteFormPartial;
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue'];
  handleChange: ReturnType<typeof useFormik>['handleChange'];
  updateForm: (values: QuoteFormPartial) => void;
  nextPage: () => void;
  prevPage: () => void;
  touched: FormikTouched<BookingQuote>;
  handleBlur: (
    event: React.ChangeEvent<
      | HTMLElement
      | {
          name?: string | undefined;
          value: unknown;
        }
    >,
  ) => void;
  isErrorsVisible: boolean;
  isFieldInFocus?: (
    name: string,
  ) => React.MutableRefObject<null | HTMLInputElement> | undefined;
};

function getConsultantId(location: WindowLocation<unknown>): string | null {
  const params = new URLSearchParams(location.search);

  return params.get('consultantId');
}

function getReferrerId(location: WindowLocation<unknown>): string | null {
  const params = new URLSearchParams(location.search);

  return params.get('referrer');
}

function getDefaultQuoteType(
  location: WindowLocation<unknown>,
): QuoteForm['quoteType'] | undefined {
  const params = new URLSearchParams(location.search);
  const quoteType = params.get('type');
  if (quoteType === 'domestic' || quoteType === 'international') {
    return quoteType;
  }
  return undefined;
}

function getContactFormParams(
  location: WindowLocation<unknown>,
): Partial<ContactForm> {
  const params = new URLSearchParams(location.search);
  // Collect relevant values
  const formValues: Partial<ContactForm> = {
    nameFirst: params.get('nameFirst') ?? undefined,
    nameLast: params.get('nameLast') ?? undefined,
    email: params.get('email') ?? undefined,
    phone: params.get('phone') ?? undefined,
  };
  return formValues;
}

function shouldPrefillForm(location: WindowLocation<unknown>): boolean {
  const params = new URLSearchParams(location.search);
  return params.get('prefill') === 'yes';
}

function clearUrlParams(): void {
  if (typeof window === 'undefined') {
    return;
  }
  window.history.replaceState(
    {},
    document.title,
    location.origin + location.pathname,
  );
}

function formatInputAddress(
  inputAddress: string | undefined,
  postcode: string | undefined,
): string | undefined {
  const [street, city, ...rest] = inputAddress?.split(',') ?? [];

  return (
    street &&
    city &&
    `${street},${city} ${postcode}${rest.reduce(
      (prevValue, currValue) => `${prevValue},${currValue}`,
      '',
    )}`
  );
}

export const FormQuote: React.FC<Props> = ({ block }) => {
  const classes = useBlockStyles(useFormStyles, block);
  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [isMounted, setMounted] = useState<boolean>(false);
  const { setOverlay } = useContext(LoadingContext);
  const sendAnalyticsEvent = useAnalytics();
  const [activeStep, setActiveStep] = useState(1);
  const prevActiveStep = useRef<number>(activeStep - 1);
  const localeCountryId = defaultLocale.slice(-2); // eg AU, NZ, GB
  const { setBookingQuote, bookingQuote, setQuoteConfiguration } = useStore(
    (state) => state,
  );
  const location = useLocation();
  const formElementId = 'form-submission-errors';
  const { updateQuoteConfiguration } = useQuoteConfiguration();
  const {
    isFieldInFocus,
    setFieldInFocus,
    isErrorsVisible,
    fieldInFocus,
    setErrorsVisible,
  } = useScrollToError(formElementId);
  const tempBookingId = new URLSearchParams(location.search).get(
    'tempBookingId',
  );

  useEffect(() => {
    if (tempBookingId) {
      setOverlay('Loading...');
      fetch(`${API_URL}/bookings/temp/${tempBookingId}`, {
        method: 'GET',
      })
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setValues((old) => ({ ...old, ...data }));
            setActiveStep(2);
            setOverlay(undefined);
          }
        });
    }
  }, []);

  // Detect document ready state
  useEffect(() => {
    setMounted(true);
    setQuoteConfiguration(null);
  }, []);

  const formik = useFormik<QuoteFormPartial>({
    initialValues:
      shouldPrefillForm(location) && bookingQuote
        ? bookingQuoteToQuoteForm(bookingQuote)
        : initRootFormValues(getDefaultQuoteType(location)),
    validationSchema: initValidationSchema(localeCountryId),
    onSubmit: async ({
      contact,
      pets,
      arrival,
      departure,
      options,
      ...rest
    }: QuoteFormPartial) => {
      setOverlay('Submitting quote..');

      const { petAccommodation, ...restOptions } = options ?? {};

      const { deliveryAddress, deliveryDistance, ...restArrival } =
        arrival ?? {};
      const { pickupAddress, pickupDistance, ...restDeparture } =
        departure ?? {};

      const formatedValues = {
        ...rest,
        options: {
          ...restOptions,
          ...(!!options?.requiresPetAccommodation && {
            petAccommodation,
          }),
          ...(!options?.isMoving && { isDataSharingEnabled: false }),
        },
        contact: {
          ...contact,
          email: contact?.email?.trim() || '',
          nameFirst: contact?.nameFirst?.trim() || '',
          nameLast: contact?.nameLast?.trim() || '',
          phone: contact?.phone?.trim() || '',
        },
        arrival: {
          ...restArrival,
          ...(deliveryDistance && {
            deliveryDistance,
          }),
          ...(arrival?.isDelivery && {
            deliveryAddress: {
              ...deliveryAddress,
              address: arrival?.deliveryAddress?.postcode
                ? formatInputAddress(
                    arrival?.deliveryAddress?.address,
                    arrival?.deliveryAddress?.postcode,
                  )
                : arrival?.deliveryAddress?.address,
              postcode: arrival?.deliveryAddress?.postcode || '',
            },
          }),
        },
        departure: {
          ...restDeparture,
          ...(pickupDistance && {
            pickupDistance,
          }),
          ...(departure?.isPickup && {
            pickupAddress: {
              ...pickupAddress,
              address: departure?.pickupAddress?.postcode
                ? formatInputAddress(
                    departure?.pickupAddress?.address,
                    departure?.pickupAddress?.postcode,
                  )
                : departure?.pickupAddress?.address,
              postcode: departure?.pickupAddress?.postcode || '',
            },
          }),
        },
        pets: pets?.map(({ name, crate, weight, breedId, ...rest }) => ({
          ...rest,
          ...(breedId && {
            breedId: /^Other/.test(breedId) ? 'Other' : breedId,
          }),
          weight: +(weight || 0),
          ...(crate &&
            Object.keys(crate).length !== 0 && {
              crate:
                crate?.type === 'own'
                  ? {
                      type: crate.type,
                      height: +(crate?.height || 0),
                      width: +(crate?.width || 0),
                      length: +(crate?.length || 0),
                    }
                  : { type: crate?.type },
            }),
          name: name?.trim() || '',
        })),
      } as QuoteFormPartial;

      try {
        const bookingQuote = await submitForm(formatedValues);

        setBookingQuote(bookingQuote);

        const {
          quoteType,
          arrival,
          departure,
          pets,
          options,
          consultantId,
          referrerId,
          contact,
        } = bookingQuote;

        sendAnalyticsEvent({
          event:
            quoteType === 'domestic'
              ? 'quoteFormDomesticSubmitted'
              : 'quoteFormInternationalSubmitted',
          value: {
            ...(bookingQuote.totalCost
              ? {
                  orderValue: bookingQuote.totalCost.amount,
                  currency: 'AUD',
                }
              : {}),
            eligibleForOnlinePayment: !!bookingQuote.totalCost,
            pets: pets.map((pet) => ({ type: pet.typeId, breed: pet.breedId })),
            petTypes: pets.map((pet) => pet.typeId),
            petBreeds: pets.map((pet) => pet.breedId),
            from: departure.airportId,
            fromCountry: departure.countryId,
            to: arrival.airportId,
            toCountry: arrival.countryId,
            pickup: departure.isPickup,
            deliver: arrival.isDelivery,
            postcode: contact.postcode,
            office: DEFAULT_LOCALE.split('-')[1],
            returnFlight: options.requiresReturnFlight,
            moving: options.isMoving,
            breeder: options.isBreeder,
            exQuarantine: options.isExQuarantine,
            boarding: options.requiresPetAccommodation,
            boardingDays: calculateDayDateDiff(
              options.petAccommodation?.end as string,
              options.petAccommodation?.start as string,
            ),
            consultantId: consultantId,
            referrerId: referrerId,
            travelDate: departure.date,
          },
        });
        setToastMsg('Quote submitted!');

        if (isInFrame()) {
          if (window.top) {
            window.top.location = `${window.location.origin}/quote-processing/?quoteId=${bookingQuote.id}`;
          }
        } else {
          navigate(localiseUrl('quote-request-received'));
        }
      } catch (e: unknown) {
        setToastMsg((e as Error).message);
        setBookingQuote(null);
      } finally {
        setOverlay(undefined);
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    errors,
    isValid,
    values,
    setValues,
    touched,
    setFieldValue,
    handleBlur,
  } = formik;

  const errorMsgs = useMemo(() => parseErrors(errors), [errors]);

  const calculateDistance = async (
    departure: QuoteFormPartial['departure'],
    arrival: QuoteFormPartial['arrival'],
  ) => {
    const pickupGPS = departure?.pickupAddress?.gps;
    const deliveryGPS = arrival?.deliveryAddress?.gps;

    if (pickupGPS && deliveryGPS) {
      const departureCoordinats = processGpsForDistance(pickupGPS);
      const arrivalCoordinats = processGpsForDistance(deliveryGPS);

      if (departureCoordinats && arrivalCoordinats) {
        const { distance, distanceThroughLondon } = await totalDistance(
          departureCoordinats.reverse(),
          arrivalCoordinats.reverse(),
        );
        return {
          totalDistance: distance,
          distanceThroughLondon: distanceThroughLondon,
        };
      }
    }
  };

  useEffect(() => {
    // contact postcode is added or distance for pickup/delivery is needed
    if (activeStep === 3) {
      updateQuoteConfiguration(formik.values as unknown as QuoteForm);

      const arrival = formik.values.arrival;
      const departure = formik.values.departure;

      if (isGBLocale && formik.values.quoteType === 'domestic') {
        setFieldValue('arrival.isDelivery', true);
        setFieldValue('departure.isPickup', true);
      }

      if (
        arrival?.isDelivery &&
        arrival?.deliveryAddress?.gps &&
        arrival?.airportId
      ) {
        const distance = getDistanceBeetwenPlaces(
          arrival.deliveryAddress.gps,
          arrival.airportId,
        );

        distance && setFieldValue('arrival.deliveryDistance', distance);
      }

      if (
        departure?.isPickup &&
        departure?.pickupAddress?.gps &&
        departure?.airportId
      ) {
        const distance = getDistanceBeetwenPlaces(
          departure.pickupAddress.gps,
          departure.airportId,
        );

        distance && setFieldValue('departure.pickupDistance', distance);
      }
    }
  }, [activeStep, formik.values]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      !fieldInFocus && window.scrollTo(0, 0);
    }
  }, [activeStep]);

  // Read / clear query params + set dynamic form defaults
  useEffect(() => {
    // Quote Type
    const quoteType = getDefaultQuoteType(location);
    const consultantId = getConsultantId(location);
    const referrerId = getReferrerId(location);

    if (consultantId) {
      setFieldValue('consultantId', consultantId);
    }

    if (referrerId) {
      setFieldValue('referrerId', referrerId);
    }

    if (quoteType) {
      setFieldValue('quoteType', quoteType);
      setActiveStep(2);
    }

    // Ex-Quarantine
    if (quoteType === 'domestic' || localeCountryId === 'GB') {
      setFieldValue('options.isExQuarantine', false);
    }

    // Country
    setFieldValue('contact.countryId', localeCountryId);

    // Contact Form
    for (const [key, value] of Object.entries(getContactFormParams(location))) {
      if (!value) {
        continue;
      }
      setFieldValue(`contact.${key}`, value);
    }

    clearUrlParams();
  }, []);

  async function submitForm(formData: QuoteFormPartial): Promise<BookingQuote> {
    let roadTransportInformation;

    if (isGBLocale && formData.quoteType === 'domestic') {
      roadTransportInformation = await calculateDistance(
        formData.departure,
        formData.arrival,
      );
    }

    const result = await fetch(`${API_URL}/bookings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        booking: {
          ...formData,
          ...(roadTransportInformation && { roadTransportInformation }),
        },
      }),
    });

    // Handle submission error
    if (result.status !== 200) {
      let responseBody = '';
      try {
        responseBody = await result.json();
      } catch {
        responseBody = await result.text();
      }
      console.error('Error submitting form', responseBody);
      throw Error(
        'Unable to submit form - please try again, or give us a call',
      );
    }

    return result.json();
  }

  function getActivePage(step: number): React.FC<PageFormProps> {
    switch (step) {
      case 1:
        return QuotePage1;
      case 2:
        return QuotePage2;
      case 3:
        return QuotePage3;
      case 4:
        return QuotePage4;
      case 5:
        return QuotePage5;
      default:
        throw Error(`Unknown form page #${step}`);
    }
  }

  const ActiveForm = getActivePage(activeStep);

  function updateForm(newData: QuoteFormPartial) {
    setValues({
      ...newData,
    });
  }

  const saveStepData = useCallback(
    async (booking?: RecursivePartial<QuoteForm>) => {
      await fetch(`${API_URL}/bookings/step`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          booking: {
            id: formik.values.id,
            ...booking,
          },
          step: activeStep - 1,
        }),
      });
    },
    [activeStep],
  );

  useEffect(() => {
    if (prevActiveStep.current < activeStep) {
      const { contact, pets, quoteType, arrival, departure } = formik.values;
      switch (activeStep) {
        case 1:
          saveStepData();
          break;
        case 2:
          saveStepData({ quoteType });
          break;
        case 3:
          saveStepData({ arrival, departure });
          sendAnalyticsEvent({
            event: 'travelDetailsCompleted',
            value: {
              allValidationPassed:
                !formik.errors.arrival && !formik.errors.departure,
            },
          });
          break;
        case 4:
          saveStepData({ contact });
          sendAnalyticsEvent({
            event: 'customerContactCompleted',
            value: {
              allValidationPassed: !formik.errors.contact,
            },
          });
          break;
        case 5:
          saveStepData({ pets });
          sendAnalyticsEvent({
            event: 'petInformationCompleted',
            value: {
              allValidationPassed: !formik.errors.pets,
            },
          });
          break;
        default:
          return;
      }
    }
  }, [activeStep]);

  function nextStep() {
    prevActiveStep.current = activeStep;
    setActiveStep((c) => c + 1);
  }

  function prevStep() {
    setActiveStep((c) => c - 1);
  }

  function goToStep(step: number) {
    setActiveStep(step);
  }

  function onClickError(field: string) {
    goToStep(mapFieldToPage(field));
    setFieldInFocus(field);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      {isMounted ? (
        <Box className={classes.root}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    <QuoteChat />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={10}>
                <FormErrorProvider value={{ errors, touched }}>
                  <ActiveForm
                    values={values}
                    errors={errors as QuoteFormPartial}
                    setFieldValue={setFieldValue}
                    updateForm={updateForm}
                    handleChange={handleChange}
                    nextPage={nextStep}
                    prevPage={prevStep}
                    handleBlur={handleBlur}
                    touched={touched as FormikTouched<BookingQuote>}
                    isErrorsVisible={isErrorsVisible}
                    isFieldInFocus={isFieldInFocus}
                  >
                    {isErrorsVisible && !!errorMsgs.length && (
                      <ErrorBlock
                        onClickError={onClickError}
                        formElementId={formElementId}
                        errorMsgs={errorMsgs}
                      />
                    )}
                  </ActiveForm>
                </FormErrorProvider>
              </Grid>
              {activeStep > 1 && (
                <Grid item xs={12} md={10} style={{ paddingTop: '4rem' }}>
                  <PageControl
                    currentPage={activeStep}
                    totalPages={5}
                    nextPage={nextStep}
                    prevPage={prevStep}
                    canSubmit={isValid}
                    setToastMsg={setToastMsg}
                    setErrorsVisible={setErrorsVisible}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      ) : (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            paddingTop: '25%',
            textAlign: 'center',
          }}
        >
          <CircularProgress size="4rem" />
        </Box>
      )}
    </>
  );
};
