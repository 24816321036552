import classNames from 'classnames';
import React, { Fragment, useMemo } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Block } from '@ict-trust/dgt-blocks/src/types/_blocks.types';
import { Box, Container, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLocation } from '@reach/router';

import {
  BrandsBlock,
  ContentBlock,
  CtaBlock,
  FaqBlock,
  FeaturesBlock,
  FormBlock,
  HeadingBlock,
  HeroBlock,
  IconGridBlock,
  ImageBlock,
  LocationsBlock,
  ReviewsBlock,
  TableBlock,
  VideoBlock,
} from '../blocks';
import { HEADER_HEIGHT } from '../components/header/header';
import ThankyouSmartBooking from '../forms/booking/pages/thankyouSmartBooking';
import SubmittedContactUs from '../forms/contact/pages/SubmittedContactUs';
import CallBookingConfirmed from '../forms/quote/pages/callBookingConfirmed';
import { EmailsPreview } from '../forms/quote/pages/emailPreview';
import Payment from '../forms/quote/pages/payment';
import QuoteConfirmation from '../forms/quote/pages/quoteConfirmation';
import QuoteProcessing from '../forms/quote/pages/quoteProcessing';
import QuoteRequestReceived from '../forms/quote/pages/quoteRequestReceived';
import ScheduleCall from '../forms/quote/pages/scheduleCall';
import ThankyouBooking from '../forms/quote/pages/thankyouBooking';
import { useImages } from '../hooks/useImages';
import { SiteLayout } from '../layouts/site.layout';
import { defaultLocale } from '../utils/language';
import { generateBreadcrumbs } from './page.utils';
import { TemplateProps } from './template.types';

type BreadcrumbsList = {
  position: number;
  name: string;
  item: string;
  route: string;
}[];

const transparentNavPages = [
  'internationalPetTransport',
  'internationalPetTransportPetTransportUnitedKingdom',
  'domesticPetTransport',
  'petTravelBreeders',
  'internationalPetTransportPetTransportUSA',
  'internationalPetTransportPetTransportNewZealand',
];

const breadCrumbsPages = ['Services', 'Airline', 'ipata'];

const useStyles = makeStyles(() => ({
  breadcrumbs: {
    display: 'flex',
    listStyleType: 'none',
    alignItems: 'center',
    padding: 0,
    '& li': {
      marginRight: '0.5rem',
    },
  },
  route: {
    textTransform: 'capitalize',
  },
  transparentNav: {
    transform: `translateY(-${HEADER_HEIGHT.MOBILE})`,

    '@media (min-width:768px)': {
      transform: `translateY(-${HEADER_HEIGHT.DESKTOP})`,
    },
  },
  transparentNavMobile: {
    [`@media screen and (max-width:767px)`]: {
      transform: `translateY(-${HEADER_HEIGHT.MOBILE})`,
    },
  },
}));

function capitalize(word: string) {
  return word.toLowerCase().replace(/\b\w/g, (l) => l.toUpperCase());
}

function filterBlocks(
  pageId: string,
  blocks: Block[],
  localeCountryId: string,
) {
  if (pageId === 'contactUs') {
    // Eject contactUsLocations block if localeCountryId is AU
    if (localeCountryId === 'AU')
      return blocks.filter(({ id }) => id !== 'contactUsLocations');
    else return blocks.filter(({ id }) => id !== 'contactUsCta');
  }

  return blocks;
}

export const Page: React.FC<TemplateProps> = ({
  children: _children,
  ...templateProps
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { pageContext } = templateProps;
  const { pageId, blocks = [] } = pageContext;
  const { getImageData } = useImages();
  const localeCountryId = defaultLocale.slice(-2); // eg AU, NZ, GB
  let itemListElementsArr: BreadcrumbsList = [];
  let position = 1;
  let prevPath = '';

  pageContext.pagePath.split('/').map((path: string) => {
    if (path) {
      if (!itemListElementsArr.length) {
        itemListElementsArr = [
          ...itemListElementsArr,
          {
            position: position,
            name:
              pageContext.pageId != 'animalsAboardTvSeries'
                ? 'Home'
                : 'Dogtainers',
            item: `${location.origin}${prevPath}/`,
            route: prevPath,
          },
        ];
        prevPath = '/';
        position++;
      }
      prevPath = `${prevPath}${path}/`;
      itemListElementsArr = [
        ...itemListElementsArr,
        {
          position: position,
          name:
            pageContext.pageId != 'animalsAboardTvSeries'
              ? capitalize(path.replace(/-/g, ' '))
              : 'Animals Abroad - Dogtainers',
          item: `${location.origin}${prevPath}`,
          route: prevPath,
        },
      ];
      position++;
    }
  });

  const script = useMemo(
    () => generateBreadcrumbs(itemListElementsArr),
    [itemListElementsArr],
  );

  const filteredBlocks = useMemo(
    () => filterBlocks(pageId, blocks, localeCountryId),
    [pageId, blocks],
  );

  return (
    <SiteLayout {...templateProps}>
      {itemListElementsArr.length > 0 && (
        <HelmetProvider>
          <Helmet defer={false}>
            <script type={script.type}>
              {JSON.stringify(script.content, null, 2)}
            </script>
          </Helmet>
        </HelmetProvider>
      )}

      <Box
        className={classNames({
          [classes.transparentNav]: pageId === 'home',
          [classes.transparentNavMobile]: transparentNavPages.includes(pageId),
        })}
        style={{
          flex: '1',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {filteredBlocks.map((block) => {
          if (block.localeDomains?.includes(defaultLocale) ?? true) {
            switch (block.type) {
              case 'brands':
                return (
                  <BrandsBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'content':
                return (
                  <ContentBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'cta':
                return (
                  <CtaBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'faq':
                return (
                  <FaqBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'form':
                return (
                  <FormBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'features':
                return (
                  <FeaturesBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'heading':
                return (
                  <HeadingBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'table':
                return (
                  <TableBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'hero':
                return (
                  <HeroBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'icon-grid':
                return (
                  <IconGridBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'image':
                return (
                  <ImageBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'locations':
                return (
                  <LocationsBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'reviews':
                return (
                  <ReviewsBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'video':
                return (
                  <VideoBlock
                    key={block.id}
                    block={block}
                    getImageData={getImageData}
                  />
                );
              case 'emailsPreview':
                return <EmailsPreview key={'emailPreview'} />;
              case 'quoteRequestReceived':
                return <QuoteRequestReceived key={'quoteRequestReceived'} />;
              case 'scheduleCall':
                return <ScheduleCall key={'scheduleCall'} />;
              case 'confirmation':
                return <QuoteConfirmation key={'confirmation'} />;
              case 'thankyouBooking':
                return <ThankyouBooking key={'thankyouBooking'} />;
              case 'thankyouSmartBooking':
                return (
                  <ThankyouSmartBooking
                    block={block}
                    getImageData={getImageData}
                    key={'thankyouSmartBooking'}
                  />
                );
              case 'payment':
                return <Payment key={'payment'} />;
              case 'callBookingConfirmed':
                return <CallBookingConfirmed key={'callBookingConfirmed'} />;
              case 'processing':
                return <QuoteProcessing key={'processing'} />;
              case 'submittedContactUsForm':
                return (
                  <SubmittedContactUs
                    block={block}
                    getImageData={getImageData}
                    key={'submittedContactUsForm'}
                  />
                );

              default:
                console.warn(
                  `Could not find handler for block "${(block as Block).type}"`,
                );
                return '';
            }
          }
        })}
      </Box>
      {breadCrumbsPages.find(
        (page) =>
          pageContext?.parentPath === page || pageContext?.pageId === page,
      ) && (
        <Box>
          <Container>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={9}>
                <ol className={classes.breadcrumbs}>
                  <li>
                    <Link href={`/`}>Home</Link>
                  </li>
                  {itemListElementsArr.length > 0 &&
                    itemListElementsArr.map(
                      (path, i) =>
                        i > 0 && (
                          <Fragment key={i}>
                            <li>
                              <Typography color="primary">/</Typography>
                            </li>
                            <li>
                              {i === itemListElementsArr.length - 1 ? (
                                path.name
                              ) : (
                                <Link
                                  href={`${path.route}`}
                                  className={classes.route}
                                >
                                  {path.name}
                                </Link>
                              )}
                            </li>
                          </Fragment>
                        ),
                    )}
                </ol>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </SiteLayout>
  );
};

export default Page;
export type { BreadcrumbsList };
