import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Image } from '@ict-trust/dgt-blocks/src/components';
import { Autocomplete, Input } from '@ict-trust/dgt-blocks/src/components/form';
import {
  BlockBuilder,
  BlockForm,
} from '@ict-trust/dgt-blocks/src/types/_blocks.types';
import { Button, Grid, Snackbar, Typography } from '@material-ui/core';

import { API_URL, RECAPTCHA_SITE_KEY } from '../../environment';
import { useAnalytics } from '../../hooks/useAnalytics';
import { LoadingContext } from '../../layouts/site.layout';
import { NestedKeyOf, getValue } from '../../utils/data';
import { QuoteForm } from '../quote/quote.types';
import { useStyles } from './contact.styles';
import {
  ContactFormPartial,
  ContactSubjectsType,
  MediaItemsType,
  RadioToggleType,
} from './contact.types';
import { validationSchema } from './contact.validation';
import ExistingQuoteEnquiry from './subjects/ExistingQuoteEnquiry';
import Feedback from './subjects/Feedback';
import GeneralEnquiry from './subjects/GeneralEnquiry';
import JobApplicationEnquiry from './subjects/JobApplicationEnquiry/JobApplicationEnquiry';
import MediaEnquiry from './subjects/MediaEnquiry';
import NewQuoteEnquiry from './subjects/NewQuoteEnquiry';

type Props = BlockBuilder<BlockForm>;

const contactSubjects: ContactSubjectsType = [
  {
    value: 'newQuoteEnquiry',
    label: 'New Quote Enquiry',
  },
  {
    value: 'existingQuoteEnquiry',
    label: 'Existing Quote Enquiry',
  },
  {
    value: 'feedback',
    label: 'Feedback',
  },
  {
    value: 'mediaEnquiry',
    label: 'Media Enquiry',
  },
  {
    value: 'generalEnquiry',
    label: 'General Enquiry',
  },
  {
    value: 'jobApplicationEnquiry',
    label: 'Submit Job Application',
  },
];

const radioToggleItems: RadioToggleType = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const mediaTypeItems: MediaItemsType = [
  {
    name: 'print',
    label: 'Print',
    value: true,
  },
  {
    name: 'digital',
    label: 'Digital',
    value: true,
  },
  {
    name: 'podcastRadio',
    label: 'Podcast / Radio',
    value: true,
  },
  {
    name: 'televisionOpportunity',
    label: 'Television Opportunity',
    value: true,
  },
  {
    name: 'guestAppearance',
    label: 'Guest Appearance',
    value: true,
  },
];

export const initialValues: ContactFormPartial = {
  nameFirst: '',
  nameLast: '',
  email: '',
  phone: '',
  contactSubject: undefined,
};

const navigateToContactSubmitted = (formValues: ContactFormPartial) => {
  const params = new URLSearchParams();

  const mapValueToParam = {
    existingQuoteEnquiry: 'ex',
    feedback: 'fb',
    mediaEnquiry: 'me',
    jobApplicationEnquiry: 'ja',
  };

  const subjectParam =
    mapValueToParam[formValues.contactSubject as keyof typeof mapValueToParam];

  const feedbackParam = (() => {
    if (subjectParam !== 'fb') return undefined;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const rating = parseInt(formValues.feedback!.rating as string);
    if (rating <= 2) return 'neg';
    if (rating <= 4) return 'neu';
    return 'pos';
  })();

  const contactRequiredParam = (() => {
    const subject = formValues.contactSubject as
      | 'existingQuoteEnquiry'
      | 'feedback'
      | 'mediaEnquiry'
      | 'jobApplicationEnquiry';
    if (subject === 'mediaEnquiry' || subject === 'jobApplicationEnquiry')
      return undefined;
    const { isContactRequired } = formValues[subject] || {};
    return isContactRequired ? 'true' : undefined;
  })();

  params.set('s', subjectParam);
  if (feedbackParam) params.set('fb', feedbackParam);
  if (contactRequiredParam) params.set('cr', contactRequiredParam);

  const queryStr = params.toString();
  navigate(`/contact-us-submitted/${queryStr.length ? '?' + queryStr : ''}`);
};

const getUrlParams = (action: 'get' | 'has', param: string) => {
  if (action === 'get') return new URLSearchParams(location.search).get(param);
  return new URLSearchParams(location.search).has(param);
};

export const FormContact: React.FC<Props> = ({ getImageData }) => {
  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const { setOverlay } = useContext(LoadingContext);
  const sendAnalyticsEvent = useAnalytics();

  const classes = useStyles();

  const formik = useFormik<ContactFormPartial>({
    initialValues,
    validationSchema,
    onSubmit: async (values: ContactFormPartial, { resetForm }) => {
      setOverlay('Submitting form..');
      try {
        await submitForm(values);
        navigateToContactSubmitted(values);
        resetForm();
        setToastMsg('Form submitted!');
      } catch (e) {
        setToastMsg((e as Error).message);
      } finally {
        setOverlay(undefined);
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
    values,
    errors,
    dirty,
    isValid,
  } = formik;

  useEffect(() => {
    const bookingId = getUrlParams('get', 'bookingId');
    const getContactData = async () => {
      try {
        setOverlay('Loading...');
        const response = await fetch(
          `${API_URL}/bookings/${
            getUrlParams('get', 'isTmp') === 'true' ? 'temp' : ''
          }/${bookingId}`,
          {
            method: 'GET',
          },
        );
        const { contact, createdAt, departure } = await response.json();
        setValues((old) => ({
          ...old,
          ...contact,
          contactSubject: 'existingQuoteEnquiry',
          existingQuoteEnquiry: {
            isQuoteReceived: false,
            submitQuoteDate: createdAt.substring(0, 10),
            idealTravelDate: departure.date,
          },
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setOverlay(undefined);
      }
    };
    bookingId && getContactData();
  }, []);

  useEffect(() => {
    if (getUrlParams('has', 'applyForJob')) {
      setValues((old) => ({
        ...old,
        contactSubject: 'jobApplicationEnquiry',
      }));
    }
  }, []);

  const submitSection = useMemo(
    () => (
      <>
        <Grid item xs={12}>
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={setRecaptchaToken}
          />
        </Grid>
        <Grid item className={classes.submitBtnWrapper}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!dirty || !isValid || !recaptchaToken}
          >
            {`Send ${
              values.contactSubject === 'feedback' ? 'Feedback' : 'Enquiry'
            }`}
          </Button>
        </Grid>
      </>
    ),
    [setRecaptchaToken, recaptchaToken, dirty, isValid, values],
  );

  const isValueEqual = useCallback(
    (fieldName: string, testValue: string | boolean) =>
      getValue(values, fieldName as NestedKeyOf<ContactFormPartial>) ===
      testValue,
    [values],
  );

  const submitForm = useCallback(
    async (formData: ContactFormPartial): Promise<void> => {
      const {
        nameFirst,
        nameLast,
        email,
        phone,
        contactSubject,
        jobApplicationEnquiry,
      } = formData;
      const result = await fetch(`${API_URL}/contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          formData: {
            ...(jobApplicationEnquiry
              ? {
                  nameFirst,
                  nameLast,
                  email,
                  phone,
                  contactSubject,
                  role: jobApplicationEnquiry?.role,
                  about: jobApplicationEnquiry?.about,
                  attachments: {
                    resume: jobApplicationEnquiry.resume,
                    coverLetter: jobApplicationEnquiry.coverLetter,
                  },
                }
              : formData),
          },
          recaptchaToken,
        }),
      });

      // Handle submission error
      if (result.status !== 200) {
        console.error('Error submitting form', result);
        throw Error(
          'Unable to submit form - please try again, or give us a call',
        );
      }

      sendAnalyticsEvent({
        event:
          result.status === 200
            ? 'contactFormSubmitted'
            : 'contactFormSubmissionError',
      });
    },
    [sendAnalyticsEvent, recaptchaToken],
  );

  const navigateToQuote = useCallback(
    (quoteType: QuoteForm['quoteType']) => {
      const params = new URLSearchParams();
      for (const [key, value] of Object.entries(values)) {
        if (!value) {
          continue;
        }
        params.set(key, value as string);
      }
      params.set('type', quoteType);
      const queryStr = params.toString();
      navigate(`/quote/${queryStr.length ? '?' + queryStr : ''}`);
    },
    [values],
  );

  const contactSubjectSection = useMemo(() => {
    const mapContactSubjects = {
      newQuoteEnquiry: <NewQuoteEnquiry navigateToQuote={navigateToQuote} />,
      existingQuoteEnquiry: (
        <ExistingQuoteEnquiry
          values={values}
          handleChange={handleChange}
          isValueEqual={isValueEqual}
          radioToggleItems={radioToggleItems}
          errors={errors as ContactFormPartial}
          submitSection={submitSection}
        />
      ),
      feedback: (
        <Feedback
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          radioToggleItems={radioToggleItems}
          errors={errors as ContactFormPartial}
          submitSection={submitSection}
        />
      ),
      mediaEnquiry: (
        <MediaEnquiry
          values={values}
          mediaTypeItems={mediaTypeItems}
          handleChange={handleChange}
          errors={errors as ContactFormPartial}
          submitSection={submitSection}
        />
      ),
      generalEnquiry: (
        <GeneralEnquiry
          submitSection={
            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={setRecaptchaToken}
              />
            </Grid>
          }
          recaptchaToken={recaptchaToken}
        />
      ),
      jobApplicationEnquiry: (
        <JobApplicationEnquiry
          values={values}
          recaptchaToken={recaptchaToken}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          errors={errors as ContactFormPartial}
          submitSection={submitSection}
        />
      ),
    };

    return mapContactSubjects[
      values.contactSubject as keyof typeof mapContactSubjects
    ];
  }, [
    navigateToQuote,
    values,
    handleChange,
    isValueEqual,
    radioToggleItems,
    errors,
    setFieldValue,
    mediaTypeItems,
    setRecaptchaToken,
    recaptchaToken,
  ]);

  const handleChangeContactSubject = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const { value: selectedSubjectName } = event.target;

      contactSubjects.forEach(({ value: subjectName }) => {
        if (subjectName !== selectedSubjectName)
          setFieldValue(subjectName, undefined);
      });
      handleChange(event);
    },
    [handleChange],
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      <Grid container spacing={4} className={classes.mainContainer}>
        <Grid item xs={10}>
          <Typography variant="h4">Contact Us</Typography>
          <Typography className={classes.headerParagraph}>
            Have a question or feedback for us?
          </Typography>
        </Grid>
        <Grid item xs={false} sm={4} md={4} className={classes.imageWrapper}>
          <Image
            getImageData={getImageData}
            src={'portholes/contact-us-dog.webp'}
            alt={'Contact Image'}
            options={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Input
                    label="First Name"
                    name="nameFirst"
                    placeholder="Your name here"
                    onChange={handleChange}
                    value={values.nameFirst}
                    error={errors.nameFirst}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Last Name"
                    name="nameLast"
                    placeholder="Your last name here"
                    onChange={handleChange}
                    value={values.nameLast}
                    error={errors.nameLast}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Input
                  label="Email"
                  name="email"
                  placeholder="e.g. name@email.com"
                  onChange={handleChange}
                  value={values.email}
                  error={errors.email}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Phone"
                  name="phone"
                  placeholder="e.g. +61 888 888 888 "
                  onChange={handleChange}
                  value={values.phone}
                  error={errors.phone}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  label="What can we help you with?"
                  name="contactSubject"
                  menuItems={contactSubjects}
                  error={errors.contactSubject}
                  value={values.contactSubject}
                  onChange={handleChangeContactSubject}
                />
              </Grid>
              {contactSubjectSection}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};
