import React from 'react';

import {
  IconLetterA,
  IconLetterB,
} from '@ict-trust/dgt-blocks/src/components/icon';
import { Button, Grid, Typography } from '@material-ui/core';

import { PageFormProps, useFormStyles } from '../quote.form';
import { QuoteForm } from '../quote.types';

export const QuotePage1: React.FC<PageFormProps> = ({
  nextPage,
  values,
  updateForm,
}) => {
  const classes = useFormStyles();
  function setQuoteType(quoteType: QuoteForm['quoteType']) {
    updateForm({
      ...values,
      quoteType,
    });
    nextPage();
  }

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">How far are we going together?</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          So we can ask the right questions to get you the best quote please let
          us know if your pet is travelling domestically or heading overseas.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={4}>
            <Button
              className={classes.buttonWhite}
              variant="outlined"
              size="large"
              fullWidth
              startIcon={<IconLetterA />}
              onClick={() => setQuoteType('domestic')}
            >
              Domestic
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              className={classes.buttonWhite}
              variant="outlined"
              size="large"
              fullWidth
              startIcon={<IconLetterB />}
              onClick={() => setQuoteType('international')}
            >
              International
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
