import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import { Image } from '@ict-trust/dgt-blocks/src/components';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { WhiteButton } from '../../../components/buttons';
import { useStore } from '../../../components/store';
import { useEnsureQuoteForm } from '../../../hooks/useEnsureQuoteForm';
import { useImages } from '../../../hooks/useImages';
import { useQuoteAirports } from '../../../hooks/useQuoteAirpotrs';
import { articleForWord } from '../../../utils/articleForWord';
import { localiseUrl } from '../../../utils/language';
import commonStyles from './styles';

const ThankyouBooking: React.FC = ({}) => {
  const commonClasses = commonStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const setBookingQuote = useStore((state) => state.setBookingQuote);
  const setQuoteConfiguration = useStore(
    (state) => state.setQuoteConfiguration,
  );

  const quoteFormData = useStore((state) => state.bookingQuote);
  const { depatureAirport, arrivalAirport } = useQuoteAirports();
  const { getImageData } = useImages();

  useEnsureQuoteForm();

  // Remove quote form data when leaving page
  useEffect(() => {
    return () => {
      setBookingQuote(null);
      setQuoteConfiguration(null);
    };
  }, []);

  const onBackHome = () => {
    const url = '/';
    navigate(localiseUrl(url));
  };

  return (
    <Container maxWidth="sm" className={commonClasses.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={commonClasses.header}>
            <Typography variant={matches ? 'h4' : 'h3'} gutterBottom>
              Thank you for booking!
            </Typography>
            <Typography variant="h5">We will contact you soon.</Typography>
          </Box>

          <Box mb={4}>
            <Typography>
              You have booked the transport of
              {quoteFormData?.pets?.[0].breedId &&
                articleForWord(quoteFormData.pets[0].breedId)}
              <span className="highlight">
                {quoteFormData?.pets?.[0].breedId}
              </span>{' '}
              from <span className="highlight">{depatureAirport}</span> to{' '}
              <span className="highlight">{arrivalAirport}</span> on{' '}
              <span className="highlight">
                {quoteFormData?.departure?.date}.
              </span>{' '}
              Check your inbox for more details about your{' '}
              {quoteFormData?.pets && quoteFormData?.pets?.length > 1
                ? "pets'"
                : "pet's"}{' '}
              travel.
            </Typography>
          </Box>

          <Box mb={5}>
            <Box mb={2}>
              <Image
                getImageData={getImageData}
                src={'dog-thankyou.webp'}
                alt="thankyou"
                options={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>

            {/* <Typography className={classes.imageCaption}>
              Image caption lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua.
            </Typography> */}
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            flexDirection={!matches ? 'row' : 'column'}
            mb={4}
          >
            <WhiteButton variant="outlined" onClick={onBackHome}>
              Back Home
            </WhiteButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThankyouBooking;
