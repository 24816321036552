import React from 'react';

import { DatePicker, Input } from '@ict-trust/dgt-blocks/src/components/form';
import { Grid } from '@material-ui/core';

import { MediaTypeProps } from '../types';

const GuestAppearance: React.FC<MediaTypeProps> = ({
  handleChange,
  values,
  errors: { mediaEnquiry: mediaEnquiryError },
}) => {
  const { guestAppearance } = values || {};
  const { guestAppearance: guestAppearanceErrors } = mediaEnquiryError || {};

  return (
    <>
      <Grid item>
        <Input
          label="Event website*"
          name="mediaEnquiry.guestAppearance.eventWebsite"
          placeholder="e.g. event.com.au"
          onChange={handleChange}
          value={guestAppearance?.eventWebsite}
          error={guestAppearanceErrors?.eventWebsite}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label="Event date"
          name="mediaEnquiry.guestAppearance.eventDate"
          onChange={handleChange}
          value={guestAppearance?.eventDate}
          error={guestAppearanceErrors?.eventDate}
        />
      </Grid>
      <Grid item>
        <Input
          label="Event topic"
          name="mediaEnquiry.guestAppearance.eventTopic"
          placeholder="e.g. Pet Travel"
          onChange={handleChange}
          value={guestAppearance?.eventTopic}
          error={guestAppearanceErrors?.eventTopic}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Event details"
          placeholder="Give us a quick rundown of the event."
          name="mediaEnquiry.guestAppearance.eventDetails"
          onChange={handleChange}
          value={guestAppearance?.eventDetails}
          error={guestAppearanceErrors?.eventDetails}
        />
      </Grid>
      <Grid item>
        <Input
          multiline
          rows={6}
          label="Appearance details"
          placeholder="What is required of us at this event? Any extra info you think we may need?"
          name="mediaEnquiry.guestAppearance.appearanceDetails"
          onChange={handleChange}
          value={guestAppearance?.appearanceDetails}
          error={guestAppearanceErrors?.appearanceDetails}
        />
      </Grid>
    </>
  );
};

export default GuestAppearance;
