import { useCallback, useEffect, useMemo, useState } from 'react';

import { AutocompleteItem } from '@ict-trust/dgt-blocks/src/components/form/autocomplete';
import { debounce } from '@material-ui/core';

import { DEFAULT_LOCALE } from '../../../environment';
import {
  AddressProps,
  getGooglePlaceDetails,
  getGooglePlacePredicitions,
} from '../../../utils/googleMap.utils';
import { PageFormProps } from '../quote.form';
import { QuoteForm, QuoteFormPartial } from '../quote.types';

type PickupAndDeliveryAddress = {
  arrival: QuoteFormPartial['arrival'];
  departure: QuoteFormPartial['departure'];
  pickupAddress: Partial<QuoteForm['departure']['pickupAddress']>;
  deliveryAddress: Partial<QuoteForm['arrival']['deliveryAddress']>;
  pickupAddresses: AddressProps[];
  deliveryAddresses: AddressProps[];
  handleInputMapChange: (
    value: string,
    inputField: 'pickup' | 'delivery',
  ) => Promise<void>;
  handleAddressChange: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    inputField: string,
  ) => Promise<void>;
  generateAutocompleteItems: (addresses: AddressProps[]) => AutocompleteItem[];
};

export const usePickupAndDeliveryAddress = (
  props: Pick<PageFormProps, 'values' | 'handleChange' | 'setFieldValue'>,
): PickupAndDeliveryAddress => {
  const { values, handleChange, setFieldValue } = props;

  const arrival = useMemo(() => values.arrival, [values.arrival]);
  const deliveryAddress = useMemo(
    () => values.arrival?.deliveryAddress,
    [values.arrival?.deliveryAddress],
  );
  const departure = useMemo(() => values.departure, [values.departure]);
  const pickupAddress = useMemo(
    () => values.departure?.pickupAddress,
    [values.departure?.pickupAddress],
  );

  const [pickupAddresses, setPickupAddresses] = useState<AddressProps[]>([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState<AddressProps[]>(
    [],
  );

  const getAddressCountry = (inputField: string) => {
    if (values.quoteType === 'international') {
      if (inputField === 'pickup') {
        return departure?.countryId?.toLowerCase();
      }
      return arrival?.countryId?.toLowerCase();
    }
    return DEFAULT_LOCALE.split('-')[1].toLowerCase();
  };

  const handleInputMapChange = useCallback(
    debounce(async (value, inputField) => {
      if (value) {
        const result = await getGooglePlacePredicitions(
          value,
          getAddressCountry(inputField),
        );

        if (result) {
          const placeData = result.map(({ description, place_id }) => ({
            description,
            place_id,
          }));

          if (inputField === 'pickup') {
            setPickupAddresses(placeData);
          } else {
            setDeliveryAddresses(placeData);
          }
        }
      }
    }, 300),
    [pickupAddresses, deliveryAddresses, departure, arrival],
  );

  const handleAddressChange = useCallback(
    async (event, inputField) => {
      handleChange(event);
      const selectedItem = (
        inputField === 'departure.pickupAddress'
          ? pickupAddresses
          : deliveryAddresses
      ).find((i) => i.description === event.target.value);

      if (selectedItem && selectedItem.place_id) {
        const result = await getGooglePlaceDetails(selectedItem.place_id);
        result.address_components &&
          setFieldValue(
            `${inputField}.postcode`,
            result.address_components.find(
              (address: { types: string[] }) =>
                address.types && address.types[0] === 'postal_code',
            )?.long_name || '',
          );
        setFieldValue(
          `${inputField}.gps`,
          `${result.geometry.location.lat()},${result.geometry.location.lng()}`,
        );
        setFieldValue(`${inputField}.placeId`, selectedItem.place_id);
      }
    },
    [pickupAddresses, deliveryAddresses],
  );

  useEffect(() => {
    pickupAddress?.address &&
      setPickupAddresses([
        { description: pickupAddress?.address, place_id: '' },
      ]);
    deliveryAddress?.address &&
      setDeliveryAddresses([
        { description: deliveryAddress?.address, place_id: '' },
      ]);
  }, []);

  const generateAutocompleteItems = (
    addresses: AddressProps[],
  ): AutocompleteItem[] =>
    addresses.map(({ description }) => ({
      value: description,
      label: description,
    }));

  return {
    arrival,
    departure,
    pickupAddress,
    deliveryAddress,
    pickupAddresses,
    deliveryAddresses,
    handleInputMapChange,
    handleAddressChange,
    generateAutocompleteItems,
  };
};
