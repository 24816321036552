import React, { useMemo } from 'react';

import {
  DatePicker,
  Input,
  Radio,
} from '@ict-trust/dgt-blocks/src/components/form';
import { Grid, Typography } from '@material-ui/core';

import { calculateDayDateDiff } from '../../../../utils/date';
import { ContactFormPartial, RadioToggleType } from '../../contact.types';

type ExistingQuoteEnquiryProps = {
  values: ContactFormPartial;
  handleChange: (event: React.ChangeEvent<HTMLElement>) => void;
  isValueEqual: (fieldName: string, testValue: string | boolean) => boolean;
  radioToggleItems: RadioToggleType;
  errors: ContactFormPartial;
  submitSection: JSX.Element;
};

const ExistingQuoteEnquiry: React.FC<ExistingQuoteEnquiryProps> = (props) => {
  const {
    values: { existingQuoteEnquiry },
    handleChange,
    isValueEqual,
    radioToggleItems,
    submitSection,
    errors: { existingQuoteEnquiry: existingQuoteEnquiryErrors },
  } = props;

  const numOfDaysFromSubmitRequest = useMemo(() => {
    if (!existingQuoteEnquiry?.submitQuoteDate?.length) return undefined;
    return Math.floor(
      calculateDayDateDiff(
        existingQuoteEnquiry?.submitQuoteDate,
        new Date(),
      ) as number,
    );
  }, [existingQuoteEnquiry?.submitQuoteDate]);

  return (
    <>
      <Grid item>
        <Radio
          label="Have you already received your quote?"
          name="existingQuoteEnquiry.isQuoteReceived"
          onChange={handleChange}
          menuItems={radioToggleItems}
          value={existingQuoteEnquiry?.isQuoteReceived}
          error={String(existingQuoteEnquiryErrors?.isQuoteReceived)}
        />
      </Grid>

      {isValueEqual('existingQuoteEnquiry.isQuoteReceived', true) && (
        <>
          <Grid item>
            <Input
              label="Quote Reference Number"
              name="existingQuoteEnquiry.quoteReferenceNumber"
              placeholder="Located at the top of your quote"
              onChange={handleChange}
              value={existingQuoteEnquiry?.quoteReferenceNumber}
              error={existingQuoteEnquiryErrors?.quoteReferenceNumber}
            />
          </Grid>
          <Grid item>
            <DatePicker
              label="What is your ideal travel date?"
              name="existingQuoteEnquiry.idealTravelDate"
              onChange={handleChange}
              value={existingQuoteEnquiry?.idealTravelDate}
              error={existingQuoteEnquiryErrors?.idealTravelDate}
            />
          </Grid>
          <Grid item>
            <Input
              multiline
              rows={6}
              label="Ask us a question"
              placeholder="Add your question here "
              name="existingQuoteEnquiry.question"
              onChange={handleChange}
              value={existingQuoteEnquiry?.question}
              error={existingQuoteEnquiryErrors?.question}
            />
          </Grid>
          <Grid item>
            <Radio
              label="Would you like to be contacted regarding your enquiry?"
              name="existingQuoteEnquiry.isContactRequired"
              onChange={handleChange}
              menuItems={radioToggleItems}
              value={existingQuoteEnquiry?.isContactRequired}
              error={String(existingQuoteEnquiryErrors?.isContactRequired)}
            />
          </Grid>
          {submitSection}
        </>
      )}

      {isValueEqual('existingQuoteEnquiry.isQuoteReceived', false) && (
        <>
          <Grid item>
            <DatePicker
              label="When did you submit your quote?"
              name="existingQuoteEnquiry.submitQuoteDate"
              onChange={handleChange}
              value={existingQuoteEnquiry?.submitQuoteDate}
              error={existingQuoteEnquiryErrors?.submitQuoteDate}
            />
          </Grid>

          {!existingQuoteEnquiryErrors?.submitQuoteDate && (
            <>
              {numOfDaysFromSubmitRequest !== undefined &&
                (numOfDaysFromSubmitRequest >= 7 ? (
                  <>
                    {/* More than 7 days ago */}
                    <Grid item>
                      <DatePicker
                        label="What is your ideal travel date?"
                        name="existingQuoteEnquiry.idealTravelDate"
                        onChange={handleChange}
                        value={existingQuoteEnquiry?.idealTravelDate}
                        error={existingQuoteEnquiryErrors?.idealTravelDate}
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        multiline
                        rows={6}
                        label="Ask us a question"
                        placeholder="Add your question here "
                        name="existingQuoteEnquiry.question"
                        onChange={handleChange}
                        value={existingQuoteEnquiry?.question}
                        error={existingQuoteEnquiryErrors?.question}
                      />
                    </Grid>
                    <Grid item>
                      <Radio
                        label="Would you like to be contacted regarding your enquiry?"
                        name="existingQuoteEnquiry.isContactRequired"
                        onChange={handleChange}
                        menuItems={radioToggleItems}
                        value={existingQuoteEnquiry?.isContactRequired}
                        error={String(
                          existingQuoteEnquiryErrors?.isContactRequired,
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    {/* Less than 7 days ago */}
                    <Grid item>
                      <Typography>
                        {`Your quote was submitted ${
                          numOfDaysFromSubmitRequest === 0
                            ? 'within the last 24 hours'
                            : numOfDaysFromSubmitRequest > 1
                            ? numOfDaysFromSubmitRequest + ' days ago'
                            : numOfDaysFromSubmitRequest + ' day ago'
                        }. Quotes are currently
                    taking up to 7 days to process by our team.`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Radio
                        label="Would you still like to process an enquiry?"
                        name="existingQuoteEnquiry.stillProcessEnquiry"
                        onChange={handleChange}
                        menuItems={radioToggleItems}
                        value={existingQuoteEnquiry?.stillProcessEnquiry}
                        error={String(
                          existingQuoteEnquiryErrors?.stillProcessEnquiry,
                        )}
                      />
                    </Grid>
                    {isValueEqual(
                      'existingQuoteEnquiry.stillProcessEnquiry',
                      true,
                    ) && (
                      <>
                        <Grid item>
                          <DatePicker
                            label="What is your ideal travel date?"
                            name="existingQuoteEnquiry.idealTravelDate"
                            onChange={handleChange}
                            value={existingQuoteEnquiry?.idealTravelDate}
                            error={existingQuoteEnquiryErrors?.idealTravelDate}
                          />
                        </Grid>
                        <Grid item>
                          <Input
                            multiline
                            rows={6}
                            label="Ask us a question"
                            placeholder="Add your question here "
                            name="existingQuoteEnquiry.question"
                            onChange={handleChange}
                            value={existingQuoteEnquiry?.question}
                            error={existingQuoteEnquiryErrors?.question}
                          />
                        </Grid>
                        <Grid item>
                          <Radio
                            label="Would you like to be contacted regarding your enquiry?"
                            name="existingQuoteEnquiry.isContactRequired"
                            onChange={handleChange}
                            menuItems={radioToggleItems}
                            value={existingQuoteEnquiry?.isContactRequired}
                            error={String(
                              existingQuoteEnquiryErrors?.isContactRequired,
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              {submitSection}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ExistingQuoteEnquiry;
