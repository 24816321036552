import { navigate } from 'gatsby';
import React from 'react';

import { Image } from '@ict-trust/dgt-blocks/src/components';
import {
  BlockBuilder,
  BlockSubmittedContactUsForm,
} from '@ict-trust/dgt-blocks/src/types/_blocks.types';
import { Button, Grid, Typography } from '@material-ui/core';
import { WindowLocation, useLocation } from '@reach/router';

import { clearUrlParams } from '../../../../utils/data';
import { useStyles } from './SubmittedContactUs.styles';

const getContactFormParams = (location: WindowLocation<unknown>) => {
  const params = new URLSearchParams(location.search);
  return {
    subject: params.get('s') ?? undefined, // ['ex' | 'fb' | 'me']
    contactRequired: params.get('cr') ?? undefined, // ['true' | 'false']
    feedback: params.get('fb') ?? '', // ['neg' | 'neu' | 'pos']
  };
};

type Props = BlockBuilder<BlockSubmittedContactUsForm>;

/*
existing quote enquiry -> contact required  ==> /contact-us-submitted/?s=ex&cr=true
existing quote enquiry -> no contact required ==> /contact-us-submitted/?s=ex&cr=false
//
feedback -> negative -> contact required ==> /contact-us-submitted/?s=fb&cr=true&fb=neg
feedback -> negative -> no contact required ==> /contact-us-submitted/?s=fb&cr=false&fb=neg
//
feedback -> neutral -> contact required ==> /contact-us-submitted/?s=fb&cr=true&fb=neu
feedback -> neutral -> no contact required ==> /contact-us-submitted/?s=fb&cr=false&fb=neu
//
feedback -> positive -> contact required ==> /contact-us-submitted/?s=fb&cr=true&fb=pos
feedback -> positive -> no contact required ==> /contact-us-submitted/?s=fb&cr=false&fb=pos
//
media enquiry -> contact required ==> /contact-us-submitted/?s=me&cr=true
media enquiry -> no contact required ==> /contact-us-submitted/?s=me&cr=false
*/

const SubmittedContactUs: React.FC<Props> = ({ getImageData, block }) => {
  const location = useLocation();
  const { subject, contactRequired, feedback } = getContactFormParams(location);
  clearUrlParams();

  if (!subject) {
    navigate('/');
    return null;
  }

  const { title, subtitle, content } =
    block.items.find((item) => item.id === `${subject}${feedback}`) || {};

  const classes = useStyles();

  const buttons = (
    <>
      <Button variant="outlined" color="inherit" href={'/'}>
        <Typography>Return Home</Typography>
      </Button>

      {`${subject}${feedback}` === 'fbpos' && (
        <Button variant="outlined" color="inherit" href={'/'}>
          <Typography>Google Review</Typography>
        </Button>
      )}
    </>
  );

  return (
    <>
      <Grid container spacing={4} className={classes.mainContainer}>
        <Grid item xs={12} sm={6} md={5} className={classes.infoContainer}>
          <Grid>
            <Typography variant="h4" className={classes.heading}>
              {title}
            </Typography>

            {subtitle && (
              <Typography
                className={classes.textContainer}
                dangerouslySetInnerHTML={{ __html: subtitle }}
              ></Typography>
            )}

            {contactRequired === 'true' && (
              <Typography className={classes.paragraph}>{content}</Typography>
            )}
          </Grid>

          <Grid container className={classes.desktopButtonsWrapper}>
            {buttons}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={4} className={classes.imageWrapper}>
          <Image
            getImageData={getImageData}
            src={'portholes/contact-us-submited.webp'}
            alt={'Contact Submited Image'}
            options={{
              width: '100%',
              height: '100%',
              maxHeight: '330px',
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.mobileButtonsWrapper}>
          {buttons}
        </Grid>
      </Grid>
    </>
  );
};

export default SubmittedContactUs;
