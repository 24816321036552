import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

import { Image } from '@ict-trust/dgt-blocks/src/components';
import { Input } from '@ict-trust/dgt-blocks/src/components/form';
import {
  BlockBuilder,
  BlockForm,
} from '@ict-trust/dgt-blocks/src/types/_blocks.types';
import { Button, Grid, Snackbar, Typography } from '@material-ui/core';

import { API_URL, RECAPTCHA_SITE_KEY } from '../../environment';
import { useAnalytics } from '../../hooks/useAnalytics';
import { LoadingContext } from '../../layouts/site.layout';
import { ContactForm } from './oldContact.types';

type Props = BlockBuilder<BlockForm>;

const validationSchema = yup.object().shape({
  nameFirst: yup.string().required('First name is required'),
  nameLast: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone is required'),
  message: yup
    .string()
    .required('Message is required')
    .test(
      'min-words',
      'Please provide more detail (min. 100 words)',
      (val) => String(val ?? '').split(' ').length >= 100,
    ),
});

const initialValues: ContactForm = {
  nameFirst: '',
  nameLast: '',
  email: '',
  phone: '',
  message: '',
};

export const OldFormContact: React.FC<Props> = ({ getImageData }) => {
  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isContactConfirmed, setContactConfirmed] = useState<boolean>();
  const { setOverlay } = useContext(LoadingContext);
  const sendAnalyticsEvent = useAnalytics();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: ContactForm, { resetForm }) => {
      setOverlay('Submitting form..');
      try {
        await submitForm(values);
        resetForm();
        setToastMsg('Form submitted!');
      } catch (e) {
        setToastMsg((e as Error).message);
      } finally {
        setOverlay(undefined);
      }
    },
  });

  async function submitForm(formData: ContactForm): Promise<void> {
    const result = await fetch(`${API_URL}/contacts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        formData,
        recaptchaToken,
      }),
    });

    // Handle submission error
    if (result.status !== 200) {
      console.error('Error submitting form', result);
      throw Error(
        'Unable to submit form - please try again, or give us a call',
      );
    }

    sendAnalyticsEvent({
      event:
        result.status === 200
          ? 'contactFormSubmitted'
          : 'contactFormSubmissionError',
    });
  }

  const { handleChange, handleSubmit, values, errors, dirty, isValid } = formik;

  function navigateToQuote() {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(values)) {
      if (!value) {
        continue;
      }
      params.set(key, value);
    }
    const queryStr = params.toString();
    navigate(`/quote/${queryStr.length ? '?' + queryStr : ''}`);
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={10}>
          <Typography variant="h4">We'd love to hear from you</Typography>
        </Grid>
        <Grid item xs={false} sm={4} md={4} style={{ padding: '3rem' }}>
          <Image
            getImageData={getImageData}
            src={'portholes/contact-us-dog.webp'}
            alt={'Contact Image'}
            options={{
              width: '100%',
              height: 'auto',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={7} md={5}>
          <form
            noValidate
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Input
                  label="Name"
                  name="nameFirst"
                  onChange={handleChange}
                  value={values.nameFirst}
                  error={errors.nameFirst}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Last Name"
                  name="nameLast"
                  onChange={handleChange}
                  value={values.nameLast}
                  error={errors.nameLast}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  error={errors.email}
                />
              </Grid>
              <Grid item>
                <Input
                  label="Phone"
                  name="phone"
                  onChange={handleChange}
                  value={values.phone}
                  error={errors.phone}
                />
              </Grid>
              {!isContactConfirmed && (
                <Grid item xs={12}>
                  <Grid container justifyContent="space-around">
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => navigateToQuote()}
                      >
                        New Quote Request
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={() => setContactConfirmed(true)}
                      >
                        Something else
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isContactConfirmed && (
                <>
                  <Grid item xs={12}>
                    <Input
                      multiline
                      rows={5}
                      label="Message"
                      placeholder="Contents"
                      name="message"
                      onChange={handleChange}
                      value={values.message}
                      error={errors.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={setRecaptchaToken}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: '1rem' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!dirty || !isValid || !recaptchaToken}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};
