import React from 'react';

import { useIsDesktop } from '@ict-trust/dgt-blocks/src/hooks/useBreakpoints';
import { normaliseUrl } from '@ict-trust/dgt-blocks/src/utils/url';
import { ListItemText, Link as MuiLink } from '@material-ui/core';

export type FooterRow = { label: string; link?: string };
export type FooterCol = FooterRow[];

export const renderFooterRow = (row: FooterRow): JSX.Element => {
  const isDesktop = useIsDesktop();

  if (row.link) {
    return (
      <MuiLink href={normaliseUrl(row.link)}>
        <ListItemText style={!isDesktop ? {} : { minHeight: '1.25rem' }}>
          <span dangerouslySetInnerHTML={{ __html: row.label }} />
        </ListItemText>
      </MuiLink>
    );
  } else {
    return (
      <ListItemText style={!isDesktop ? {} : { minHeight: '1.25rem' }}>
        <span dangerouslySetInnerHTML={{ __html: row.label }} />
      </ListItemText>
    );
  }
};
